import { addSearchTermToURL } from '@/helpers/searchHelpers/addSearchTermToURL'
import { InspirationResultProps } from './results.types'

const CategoryResult: React.FC<InspirationResultProps> = ({
  searchTerm,
  inspiration: {
    source: {
      custom_url: {
        url,
      },
      image_url: imageUrl,
      name,
    },
  },
}) => (
  <li
    className="autocomplete-result autocomplete-category-result"
    data-name={name}
  >
    <a
      className="category_list"
      href={addSearchTermToURL(url, searchTerm).toString()}
      data-ae-blurbtype="link"
    >
      <img src={imageUrl} alt={name} />
      <div className="divhover">{name}</div>
    </a>
  </li>
)

export default CategoryResult
