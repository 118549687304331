import productHelpers from '@/helpers/Product'
import { addSearchTermToURL } from '@/helpers/searchHelpers/addSearchTermToURL'
import type { ProductResultProps } from './results.types'

const ProductResult: React.FC<ProductResultProps> = ({
  searchTerm,
  product,
}) => (
  <li
    className="autocomplete-result autocomplete-product-result"
    data-name={productHelpers.name(product)}
  >
    <a
      className="product_list"
      href={addSearchTermToURL(product.source.custom_url.url, searchTerm).toString()}
      data-ae-blurbtype="link"
    >
      <img src={productHelpers.thumbnail(product)} alt={productHelpers.name(product)} />
      <div className="align_middle">{productHelpers.name(product)}</div>
    </a>
  </li>
)

export default ProductResult
