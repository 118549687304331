export default {
  ENTER: 13,
  ESCAPE: 27,
  UP: 38,
  DOWN: 40,
  trap(event, keyOrList, block, otherwise) {
    if ([event.keyCode, event.charCode].some((code) => [].concat(keyOrList).includes(code))) {
      event.preventDefault()
      if (typeof block === 'function') {
        block(event)
      }
    } else if (typeof otherwise === 'function') {
      otherwise(event)
    }
  },
}
