/* eslint-disable jsx-a11y/anchor-is-valid */
import { formatPrice } from '@/helpers/common'
import useZGoldBalance from '@/hooks/zgold'
import ZGoldLogo from './ZGoldLogo'
import styles from './ZGoldBalance.module.scss'

const ZGoldBalance = () => {
  const zGoldBalance = useZGoldBalance(true)
  const handleZGoldClick = () => window?.shoptoken?.showWidget()

  if (!window.shoptoken?.isCustomerInLoyaltyProgram() || zGoldBalance === null) {
    return null
  }

  return (
    <li className="navUser-item navUser-item--zgold">
      <a className="navUser-action zgoldButton" href="#" onClick={handleZGoldClick} data-testid="header-zgoldBalance">
        <div className={styles.zgoldLogo}><ZGoldLogo /></div>
        <span data-testid="zgold-balance">
          {formatPrice(zGoldBalance)}
        </span>
      </a>
    </li>
  )
}

export default ZGoldBalance
