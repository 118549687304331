/* eslint-disable @typescript-eslint/require-await */
// Navigation

import { log } from '@/services/Log'

export const goToCart = async () => {
  window.location.href = '/cart.php'
}

export const goToHome = async () => {
  window.location.href = '/'
}

export const goToConfirmation = (input: string | number | {
  orderId: string | number
  zip: string
}): Promise<{
  success: false
}> => new Promise((resolve) => {
  const orderId: string | number = (
    typeof input === 'string' || typeof input === 'number'
      ? input
      : input?.orderId
  )
  const zip = typeof input === 'object' ? input?.zip : undefined
  log('going to checkout with orderId ', orderId)
  window.location.href = `/checkout/order-confirmation/${orderId}${zip ? `?zip=${zip}` : ''}`
  setTimeout(() => {
    resolve({ success: false })
  }, 1000)
})

export const setPageTitle = (title: string) => {
  document.title = `ZGallerie ${title}`
}

export const setCustomerLocalStorage = async ({ customerId, customerEmail }) => localStorage.setItem('customer', JSON.stringify({ customerId, customerEmail }))

export const getPageUrl = async () => window.location.href
/**
 * Update the path to the specified string. Either absolute or relative to
 * the root, without triggering a page fresh.
 */
export const updateCurrentPathTo = (path: string) => window.history.pushState({}, '', path)

export const reloadPage = () => {
  window.location.reload()
}
