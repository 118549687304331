import {
  createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useState,
} from 'react'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import type { StandardCustomer } from '@/types/ShopFront/StandardCustomer'

type CustomerContextType = {
  customer?: StandardCustomer | null,
  loading: boolean,
  error: string | null,
  setCustomer: Dispatch<SetStateAction<StandardCustomer | null>>,
  setLoading: Dispatch<SetStateAction<boolean>>
  setError: Dispatch<SetStateAction<string | null>>
}

const CustomerContext = createContext<CustomerContextType>({
  customer: undefined,
  loading: true,
  error: null,
  setCustomer: () => {},
  setLoading: () => {},
  setError: () => {},
})

interface CustomerProviderProps {
  children,
  customer?: StandardCustomer,
}

const CustomerProvider = ({
  children, customer: initialCustomer = undefined,
}: CustomerProviderProps) => {
  const [customer, setCustomer] = useState<StandardCustomer | undefined | null>(initialCustomer)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const loader = async () => {
      setLoading(true)
      const [{ getCustomer }] = await allPromisesWithRetries(() => [
        import('@/services/Account/getCustomer'),
      ])
      setCustomer(await getCustomer())
      setLoading(false)
    }
    try {
      loader()
    } catch (e) {
      setError(String(e))
    }
  }, [])

  const value = {
    customer,
    loading,
    error,
    setCustomer,
    setLoading,
    setError,
  }

  return (
    <CustomerContext.Provider value={value}>
      {children}
    </CustomerContext.Provider>
  )
}

const useCustomer = () => {
  const { customer, loading, error } = useContext(CustomerContext)
  return { customer, loading, error }
}

const useFetchCustomer = () => {
  const { setCustomer, setLoading, setError } = useContext(CustomerContext)

  return useCallback(async () => {
    setLoading(true)
    try {
      const [{ getCustomer }] = await allPromisesWithRetries(() => [
        import('@/services/Account/getCustomer'),
      ])
      const customer = await getCustomer()
      setCustomer(customer)
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      setError(String(e?.message || e))
    }
    setLoading(false)
  }, [setCustomer, setError, setLoading])
}

type CustomerUpdate = {
  id: string,
  first_name: string,
  last_name: string,
  email: string,
  phone: string,
  current_password: string,
  password: string,
  confirm_password: string,
}

const useUpdateCustomer = () => {
  const fetchCustomer = useFetchCustomer()
  const { setLoading } = useContext(CustomerContext)

  return async (newCustomer: CustomerUpdate) => {
    setLoading(true)
    const [{ updateCustomer }] = await allPromisesWithRetries(() => [
      import('@/services/Account/updateCustomer'),
    ])
    const { data: { success, message } } = await updateCustomer(newCustomer)
    if (!success) {
      return Promise.reject(new Error(String(message)))
    }
    return fetchCustomer()
  }
}

export {
  CustomerProvider,
  useUpdateCustomer,
  useFetchCustomer,
  useCustomer,
}
