import React, { useEffect, useState, useRef } from 'react'
import isEmpty from 'lodash/isEmpty'
import Configuration from '@/services/Configuration'

import { GIFT_BOX_SKU } from '@/data/constants'
import { formatMoney } from '@/helpers/formatMoney'
import clsx from "clsx";
import { useProductPrices } from "@/components/Sitewide/Navbar/NavigationContext";
import { FEATURE_TOGGLES, SHOP_HASH } from '@/services/Configuration'
import useMiniCartApplePayCheckout from '@/components/ApplePay/useCartApplePayCheckout'
import { error, log } from '@/services/Log'
import { getCart } from '@/services/FramedCheckout/orderServices/getCart'
import useCanUseApplePay from '@/hooks/useCanUseApplePay'
import previewContentStyles from './PreviewContent.module.scss'
import dynamic from 'next/dynamic'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import clearShippedShieldFromCart from '@/helpers/clearShippedShieldFromCart'
import ShippedShield from '@/components/ShippedShield'
import type { StandardCart, StandardLineItem, StandardOrder } from '@/types/ShopFront/CheckoutStandards'
import { useFetchCart } from '@/hooks/cart'
import checkIsShippedShieldItem from '@/helpers/checkIsShippedShieldItem'
import useShippedShieldExperimentChecker from '@/hooks/useShippedShieldExperimentChecker'
import isShippedShieldEligibleOrder from '@/helpers/isShippedShieldEligibleOrder'
import isDTPCustomer from '@/helpers/isDTPCustomer'
import { GraphQlCustomer, getCustomer } from '@/helpers/graphql'
import type { Consignment } from '@/types/Checkout'

const PreviewLineItem = dynamic(import('@/components/Sitewide/Navbar/CartPreview/PreviewLineItem/PreviewLineItem'))
const ApplePayCheckoutButton = dynamic(import('@/components/ApplePay/ApplePayButton'))
const PaypalCtaWithLogic = dynamic(import('@/components/PaypalCTA/PaypalCtaWithLogic'))
const PromoCode = dynamic(import('@/components/Cart/PromoCode'))

const { RESERVATION_NUMBER_FIELD } = Configuration

const shouldShowMiniCartApplePayCtaEnabled = () => (
  !!FEATURE_TOGGLES?.miniCartApplePayCtaEnabled
  && !!FEATURE_TOGGLES?.applePayEnabled
) || (new URLSearchParams(window.location.search).get('forceApplePayOn') === 'true')

const shouldShowPayPalOnMiniCart = () => (
  !!FEATURE_TOGGLES?.miniCartPayPalEnabled
  && !!FEATURE_TOGGLES?.payPalEnabled
) || (new URLSearchParams(window.location.search).get('forcePayPalOn') === 'true')

export interface PreviewContentProps {
  cart: StandardCart | null | undefined,
  lineItems: StandardLineItem[],
  cartQuantity,
  onClose: (event) => void,
  setQuantity,
  removeCoupon,
  visible: boolean,
  styles?: {
    table?: string;
    previewCartAction?: string;
    emptyCart?: string;
    dropDown?: string;
  }
}

const RenderEmptyContent: React.FC<Pick<PreviewContentProps, 'styles'> & {
  cartQuantity: number;
  onClose: (e: any) => void;
}> = ({
  styles,
  cartQuantity,
  onClose,
}) => (
  <div className={clsx("previewCart", styles?.emptyCart)}>
    <div className="minCartHeader cart-item-flex">
      <div>
        <img src="/content/images/cart-icon-white.svg" alt="cart" width="15" />
        {` Cart (${cartQuantity || 0})`}
      </div>
      <div style={{ float: 'right' }} onClick={onClose}>
        <img src="/content/images/close-white.svg" alt="close" width="12" />
      </div>
    </div>
    <div className="previewCart-emptyBody">Your cart is empty</div>
  </div>
)

const lineItemIsFurniture = (lineItem: StandardLineItem) => lineItem.isFurniture
const lineItemIsNotFurniture = (lineItem: StandardLineItem) => !lineItem.isFurniture
const findInCartLineItems = (cart: StandardCart) => (finder: (item: StandardLineItem) => boolean) => (
  !!cart.lineItems.find(finder)
)
const nonNullCartHasSplitShipment = (cart: StandardCart) => (
  findInCartLineItems(cart)(lineItemIsFurniture)
  && findInCartLineItems(cart)(lineItemIsNotFurniture)
)

const cartIsNotEmpty = (cart: StandardCart | null): cart is StandardCart => !!cart?.lineItems.length

const cartHasSplitShipment = (cart: StandardCart | null) => (
  cartIsNotEmpty(cart) && nonNullCartHasSplitShipment(cart)
)

const RenderNonEmptyContent: React.FC<Pick<PreviewContentProps,
  'lineItems'
  | 'cartQuantity'
  | 'onClose'
  | 'setQuantity'
  | 'removeCoupon'
  | 'styles'
> & {
  cart: StandardCart,
  reservationConsignment: any;
  consignments: any;
}> = ({
  cart,
  lineItems,
  cartQuantity,
  onClose,
  setQuantity,
  removeCoupon,
  styles,
  reservationConsignment,
  consignments,
}) => {
  const { productPrices } = useProductPrices()

  const {
    canUseApplePay,
    paymentRequest,
  } = useCanUseApplePay({
    price: cart.total,
    paymentMethod: 'applePay',
  })
  const [shippedShieldFee] = useState<number>()
  const [isCartEligible] = useState<boolean>()
  const [shippedShieldAvailable, setShippedShieldAvailable] = useState<boolean>(false)
  const [shippedShieldEnabled, setShippedShieldEnabled] = useState()
  const [shippedShieldExperiment, setShippedShieldExperiment] = useState<boolean>(false)
  const [customer, setCustomer] = useState<GraphQlCustomer>()
  const fetchCart = useFetchCart()
  const shippedShieldExperimentToggled = useShippedShieldExperimentChecker()

  const giftBoxing = lineItems.find((lineItem) => lineItem.sku === GIFT_BOX_SKU)
  const lineItemsToDisplay = lineItems.filter(({ sku }) => !checkIsShippedShieldItem({ sku }))
  const physicalItemsTotal = lineItemsToDisplay.reduce((totalPrice, lineItem) => totalPrice + lineItem.extendedSalePrice, 0)

  useEffect(() => {
    getCustomer().then(({ success, customer }) => {
      if (success) {
        setCustomer(customer)
      }
    })
  }, [])

  useEffect(() => {
    if (shippedShieldExperimentToggled) {
      setShippedShieldExperiment(shippedShieldExperimentToggled())
    }
  }, [shippedShieldExperimentToggled])

  const [standardCart, setStandardCart] = useState<Awaited<ReturnType<typeof getCart>>>(null)
  const [order, setOrder] = useState<StandardOrder>()
  useEffect(() => {
    getCart()
      .then(async (newStandardCart) => {
        setStandardCart(newStandardCart)
        const [
          { getOrder },
          { updateShippingAddress },
        ] = await allPromisesWithRetries(() => [
          import('@/services/FramedCheckout/orderServices/getOrder'),
          import('@/services/FramedCheckout/shipmentServices/updateShippingAddress'),
        ])

        if (newStandardCart) {
          getOrder({ cart: newStandardCart })
            .then((newOrder) => {
              if (newOrder.shipments.length === 0 && cart?.lineItems.length > 0) {
                // In case of empty shipments value with non-empty physicalItems,
                // we add consignments again
                updateShippingAddress({
                  order: newOrder,
                  address: newOrder.billingAddress,
                }).then(({success, newOrder}) => {
                  if (success && newOrder) {
                    setOrder(newOrder)
                  }
                })
                .catch(error => {
                  log('Error updating shipping address', error)
                })
              } else {
                setOrder(newOrder)
              }
            })
            .catch(error => {
              log('Error getting order', error)
            })
        }
      })
      .catch((error) => {
        log('Error getting cart', error)
      })
  }, [cart])

  useEffect(() => {
    if (order) {
      setShippedShieldAvailable(isShippedShieldEligibleOrder(order))
    }
  }, [order, customer])

  useEffect(() => {
    if (shippedShieldEnabled !== undefined && shippedShieldFee !== undefined) {
      fetchCart()
    }
  }, [shippedShieldEnabled, shippedShieldFee, isCartEligible, shippedShieldAvailable])

  useEffect(() => {
    if (lineItemsToDisplay.length === 0 && shippedShieldEnabled) {
      clearShippedShieldFromCart().then(fetchCart)
    }
  }, [lineItemsToDisplay.length, shippedShieldEnabled])

  const handleShippedShieldChange = (details) => {
    if (details.isSelected !== undefined) {
      setShippedShieldEnabled(details.isSelected)
    }
  }

  const {
    onStartCheckout,
    modalContent,
    onCloseModal,
    ctaRef,
    hideCta: hideApplePayCta,
  } = useMiniCartApplePayCheckout({
    paymentRequest,
    cart: standardCart,
    canUseApplePay,
    paymentFlow: 'mini-cart-apple-pay',
  })

  const isPayPalVisible = () => (
      standardCart?.id === cart?.id
      && !cartHasSplitShipment(standardCart)
      && shouldShowPayPalOnMiniCart()
  )

  const getAssociatedWarrantyLineItem = (lineItem) => lineItems
    .find(({ sku }) => sku.split(';')[2] === lineItem.sku)

  const getActualPrice = (lineItem: StandardLineItem) => {
    let price = lineItem.isWarranty ? false : 0
    productPrices?.forEach((product) => {
      if (lineItem.sku === product.sku) {
        if (product?.price !== product.sale_price) {
          price = product?.price
        }
      }
    })
    return price
  }

  const getTotalSavings = () => {
    if(!cart){
      return 0
    }
    let savings = 0
    const discount = !isEmpty(cart.coupons) ? cart.coupons[0].discountedAmount : 0
    lineItems?.forEach((lineItem) => {
      productPrices?.forEach((product) => {
        if (lineItem.sku === product.sku) {
          if (product.price !== product.sale_price && product.sale_price !== 0 && product.sale_price !== null) {
            savings += (product.price - product.sale_price) * lineItem.quantity
          }
        }
      })
    })
    savings += discount
    return savings
  }
  const giftBoxItem = lineItems.filter((lineItem) => lineItem.sku === GIFT_BOX_SKU)
  const dataItemPrice = Number((
    cart
      ? cart?.subtotal.toFixed(2)
      : 0
  ) || 0) * 100

  return (
    <div className="previewCart" data-cart-quantity={21}>
      <div className="minCartHeader cart-item-flex">
        <div>
          <img
            src="/content/images/cart-icon-white.svg"
            className={previewContentStyles.cartIcon}
            alt="cart"
            width="15"
          />
          {` Cart (${cartQuantity || 0}) `}
          <span>{`Subtotal: $${(
            cart
              ? cart?.total.toFixed(2)
              : 0
          )}`}</span>
        </div>
        <div style={{ float: 'right' }} onClick={onClose}>
          <img src="/content/images/close-white.svg" alt="close" width={12} />
        </div>
      </div>
      <table className={clsx('previewCartList', styles?.table)} style={{ border: 'none' }}>
        <thead className="previewCart-head">
          <tr>
            <th style={{ width: "55%" }} align="left">Product</th>
            <th colSpan={2} align="left" style={{ width: "20%" }}>Qty</th>
            <th align="right">Subtotal</th>
          </tr>
        </thead>
        <tbody className="previewCart-tbody">
          {lineItemsToDisplay.filter((lineItem) => lineItem.sku !== GIFT_BOX_SKU)
            .map((lineItem, index) => (
              <PreviewLineItem
                consignments={consignments}
                index={index}
                key={`${lineItem.id}x${lineItem.quantity}`}
                lineItem={lineItem}
                setQuantity={setQuantity}
                actualPrice={getActualPrice(lineItem)}
                giftBoxing={giftBoxing}
                warrantyLineItem={getAssociatedWarrantyLineItem(lineItem)!}
                isReservation={reservationConsignment?.lineItemIds?.includes(lineItem.id)}
              />
            ))}
        </tbody>
      </table>
      <div className={clsx('previewCartAction', styles?.previewCartAction)}>
        <PromoCode classType="CModal" />
        <div className="atc-container">
          {!isEmpty(cart?.coupons) && (
            <ul className="cart-totals">
              <li className="cart-total discount-total" id="promocode-success">
                <div className="cart-total-label">
                  <span>{`Discount(${cart?.coupons[0].code.split('__')[0]})`}</span>
                  <div onClick={removeCoupon} data-testid="minicart-promocode-clear" className="removeCoupon">Remove</div>
                </div>
                <div className="cart-total-value">
                  <span>
                    -
                    {formatMoney((
                      cart?.coupons[0].discountedAmount
                    ))}
                  </span>
                </div>
              </li>
            </ul>
          )}
          {!isEmpty(giftBoxItem) && (
            <div className="cart-total">
              <div className="cart-total-label">
                <span>Gift Boxing</span>
              </div>
              <div className="cart-total-value">
                <span>
                  {formatMoney(giftBoxItem[0].extendedSalePrice)}
                </span>
              </div>
            </div>
          )}
          <div className="cart-total subtotal">
            <div className="cart-total-label">
              <span> Subtotal</span>
            </div>
            <div className="cart-total-value">
              <span>
                {formatMoney(cart?.total)}
              </span>
            </div>
          </div>
          {getTotalSavings() > 0 && (
            <div className="cart-total savings-total" id="total-savings" style={{ display: 'block' }}>
              <div className="cart-total-label">
                <span>Total Savings</span>
              </div>
              <div className="cart-total-value">
                <span>
                  {formatMoney(getTotalSavings())}
                </span>
              </div>
            </div>
          )}
          {!!FEATURE_TOGGLES?.isShippedShieldEnabled
            && standardCart && shippedShieldExperiment &&
            <div>
              <ShippedShield
                shippedConfig={{
                  storeHash: SHOP_HASH,
                  environment: 'production',
                }}
                cartItems={{
                  lineItems: lineItemsToDisplay,
                }}
                isCartEligible={shippedShieldAvailable && !isDTPCustomer(customer)}
                onInit={handleShippedShieldChange}
                onChange={handleShippedShieldChange}
              />
            </div>
          }
          {shouldShowMiniCartApplePayCtaEnabled() && canUseApplePay && !hideApplePayCta && (
            <div style={{
              marginBottom: '8px'
            }}>
              <ApplePayCheckoutButton
                buttonHeightClassName='custom-mini-cart-apple-pay-height'
                applePayExperience="miniCart"
                onStartCheckout={onStartCheckout}
                modalContent={modalContent}
                onCloseModal={onCloseModal}
                ctaRef={ctaRef}
              />
            </div>
          )}
          {isPayPalVisible() && (
            <div style={{
              marginBottom: '4px'
            }}>
              <PaypalCtaWithLogic
                paymentFlow="mini-cart-paypal"
                ctaHeight={35}
                forceReRender={[cart]}
                cartGenerator={() => (
                  allPromisesWithRetries(() => [
                    import('@/services/FramedCheckout/orderServices/getCart')
                  ])
                  .then(([{ getCart }]) => getCart())
                  .then((cart) => (
                    cart || Promise.reject(new Error('Cart is empty'))
                  ))
                )}
                onCancel={() => (
                  allPromisesWithRetries(() => [
                    import('@/services/FramedCheckout/orderServices/deleteOrderConsignments'),
                    import('@/services/FramedCheckout/orderServices/getCart')
                  ])
                    .then(async ([
                      { deleteOrderConsignments },
                      { getCart },
                    ]) => deleteOrderConsignments(await getCart()))
                    .then(fetchCart)
                    .catch(error)
                )}
              />
            </div>
          )}
          <div className="cart-checkout-buttons">
            <div className="previewCartAction-checkout">
              <a
                href="/checkout/shipping"
                className="button button--small button--primary"
                id="checkout-CModal"
                data-testid="minicart-checkout"
              >
                Checkout
              </a>
            </div>
            <div className="previewCartAction-viewCart">
              <a className="button button--small button--action" id="viewCart-CModal" href="/cart.php" data-testid="minicart-view-cart">
                View Cart
              </a>
            </div>
          </div>

          <div style={{ textAlign: 'center' }} className="continue-shopping-btn">
            <a
              id="continueShopping-CModal"
              title="Click here to continue shopping"
              onClick={onClose}
              href="#"
            >
              <span>Continue Shopping</span>
            </a>
          </div>

        </div>
      </div>
      <div className="previewCartLeaseco">
        <div className="leasecoDiv">
          <span className="leaseco-promo" data-item-price={dataItemPrice} />
          {' '}
          <span className="leasecoPopupButton">
            <img src="/__statics/images/question-circle.svg" alt="lease" width={13} />
          </span>
        </div>
      </div>
    </div>
  )
}

const PreviewContent = ({
  cart,
  lineItems,
  cartQuantity,
  onClose,
  setQuantity,
  removeCoupon,
  visible,
  styles
}: PreviewContentProps) => {
  const mainDivRef = useRef<HTMLHeadingElement>(null)
  const [reservationConsignment, setReservationConsignment] = useState<any>({})
  const [consignments, setConsignments] = useState<Consignment[]>([])

  useEffect(() => {
    function handleClickOutside(event) {
      const clickOutsideExpandedMenu = !!(mainDivRef?.current) ? !(mainDivRef?.current?.contains(event.target)) : true;
      if (clickOutsideExpandedMenu) {
        onClose(event);
      }
    }
    // Bind the event listener
    if (visible) {
      document.addEventListener("click", handleClickOutside);
      document.addEventListener("touchend", handleClickOutside);
    }
    return () => { // Unbind the event listener on clean up
      try {
        document.removeEventListener("click", handleClickOutside);
        document.removeEventListener("touchend", handleClickOutside);
      } catch { }
    };
  }, [mainDivRef, visible]);

  useEffect(() => {
    if (cart)
    (async () => {
      const [{ Checkout }] = await allPromisesWithRetries(() => [
        import('@/services/Checkout')
      ])
      const { consignments } = await Checkout.getCheckout({ cartId: cart?.id })
      setConsignments(consignments)
      consignments?.forEach((consignment) => {
        const { shippingAddress: { customFields } } = consignment
        if (customFields.find(({ fieldId }) => fieldId === RESERVATION_NUMBER_FIELD)) {
          setReservationConsignment(consignment)
        }
      })
    })()
  }, [cart])

  useEffect(() => {
    return () => {
      document.getElementsByTagName('body')[0]
        .classList.remove('has-cart')
    }
  }, [])

  return (
    <div
      ref={mainDivRef}
      id="cart-preview-dropdown"
      className={clsx(
        styles?.dropDown,
        "dropdown-menu",
        "f-open-dropdown",
        visible && 'is-open',
        previewContentStyles.cartPreviewDropdown,
      )}
      data-dropdown-content="true"
      aria-hidden="false"
    >
      {(
        lineItems.length === 0  || !cart
      )? <RenderEmptyContent
          cartQuantity={cartQuantity}
          onClose={onClose}
          styles={styles}
        />
        : (
          <RenderNonEmptyContent
            cart={cart}
            lineItems={lineItems}
            cartQuantity={cartQuantity}
            onClose={onClose}
            setQuantity={setQuantity}
            removeCoupon={removeCoupon}
            styles={styles}
            reservationConsignment={reservationConsignment}
            consignments={consignments}
          />
        )}
    </div>
  )
}

export default PreviewContent
