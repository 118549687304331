import { log } from '@/services/Log'

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))
/*
  Since google optimize experiement results are not always present on load,
  we need a method to poll for the data OR resort to the default after 500ms.
*/
type ExperimentGetter = (i: {
  experimentId: string
  iteration?: number
}, iteration?: number) => Promise<'1' | '0'>
export const getExperiment: ExperimentGetter = ({
  experimentId,
  iteration = 0,
}) => new Promise((resolve) => {
  // if darkMode is enabled, by cookie etc., use Variant1
  if (iteration >= 10) {
    resolve('0')
  } else {
    log(`getExperiment: ${experimentId} iteration: ${iteration}`)
    log(`type of window google optimize: ${typeof window?.google_optimize?.get}`)
    const get = window?.google_optimize?.get
    const variant = get?.(experimentId)
    if (!variant && typeof get === 'function') {
      resolve('0')
    } else if (typeof variant === 'string') {
      resolve(
        variant === '1' ? '1' : '0',
      )
    } else {
      // The maximum time to get a cookie is 2.5s including all iterations
      // With 10 iterations we should be able to get a cookie in 3.5s
      sleep(250)
        .then(async () => {
          resolve(await getExperiment({ experimentId, iteration: iteration + 1 }))
        })
        .catch(() => resolve('0'))
    }
  }
})

export default getExperiment
