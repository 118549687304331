export const isShippedShieldEligibleOrder = ({ shipments = [] }: {
  shipments?: Array<{
    isBopus: boolean
    isFurniture: boolean
  }>
}) => (
  !!shipments.length
  && shipments.every((shipment) => !shipment.isBopus && !shipment.isFurniture)
)

export default isShippedShieldEligibleOrder
