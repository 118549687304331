import { BRAND } from '@/data/constants'
import { getProductName } from '@/services/Tracking/helpers'

export type RequiredProductAttributes = {
  name:string,
  sku:string,
  price: number,
  salePrice:number,
  brand?: string,
  variantId:string,
  category: string | undefined,
  categoryId?:string,
  position?: number,
  quantity?:number,
  categories?:string[],
  inventory?: number,
}
const extractPrice = (priceString: string | number): number | undefined => {
  if (typeof priceString !== 'string') {
    return priceString
  }

  const match = priceString.match(/(\d+(\.\d*)?)/)
  return match ? +match[0] : undefined
}

export const convertProductToGA4Item = (product:RequiredProductAttributes):Gtag.Item => {
  const item = {
    item_id: product.sku,
    item_name: getProductName(product.name),
    discount: parseFloat((product.price - product.salePrice).toFixed(2)),
    index: product.position ?? 0,
    item_brand: product.brand ?? BRAND,
    item_category: product.category,
    item_variant: product.variantId,
    item_list_id: String(product.categoryId),
    item_list_name: product.category,
    price: extractPrice(product.price),
    quantity: product.quantity ?? 1,
    inventory: product.inventory,
  }
  // We can have item_categories{n} in range [2,5]
  if (product.categories?.length) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < product.categories.length && i < 4; i++) {
      item[`item_category${i + 2}`] = product.categories[i] ?? 'N/A'
    }
  }
  return item
}

export default convertProductToGA4Item
