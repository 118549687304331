import { useEffect, useState } from 'react'
import { getCustomer } from '@/services/Account/getCustomer'

const useZGoldBalance = (slow = false) => {
  const [balance, setBalance] = useState<number | null>(null)
  const [storeCredit, setStoreCredits] = useState<number | null>(null)
  const updateBalance = (value: string | number) => setBalance(parseFloat(String(value)))
  useEffect(() => {
    async function initializeCustomer() {
      const customer = await getCustomer()
      const credit = parseFloat(
        String(
          customer?.storeCreditAmounts?.[0]?.amount
          || 0,
        ),
      )
      setStoreCredits(credit)
    }
    initializeCustomer()
  }, [])

  useEffect(() => {
    if (slow) {
      window.shoptoken?.subscribeToBalanceSlowRefresh?.(updateBalance)
    } else {
      window.shoptoken?.subscribeToBalanceFastRefresh?.(updateBalance)
    }
  }, [slow])

  if (balance !== null && storeCredit !== null) {
    return balance + storeCredit
  }
  return null
}

export default useZGoldBalance
