const sizeArray = ['small', 'medium', 'large', 'extra small', 'extra large', 'long', 'tall', 'short']
const nameIsSize = (name: string) => /^\d/.test(name) || sizeArray.includes(name)
const getOptionLabel = (option?: { label: string }) => String(option?.label || '').toLowerCase() || ''

export const checkVariantNameIsSize = (el: {
  option_values: Array<{
    label: string
  }>
}) => (
  nameIsSize(getOptionLabel(el.option_values?.[0]))
    ? 1
    : 0
)

export default checkVariantNameIsSize
