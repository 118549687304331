import { getExperiment } from '@/helpers/getOptimizeExperiment'
import { isFeatureEnabled } from '@/helpers/isFeatureEnabled'
import { SHIPPEDSHIELD_EXPERIMENT_ID } from '@/services/Configuration'
import { error } from '@/services/Log'
import { useEffect, useState } from 'react'

export const getShippedShiledExperimentValue = () => (
  getExperiment({ experimentId: SHIPPEDSHIELD_EXPERIMENT_ID })
    .then((experiment) => Promise.resolve(experiment === '1'))
    .catch(() => Promise.resolve(false))
)

const useShippedShieldExperimentChecker = () => {
  const [shippedShieldExperimentOn, setShippedShieldExperimentOn] = useState<boolean>(
    isFeatureEnabled('forceShippedShieldOn'),
  )

  useEffect(() => {
    if (!shippedShieldExperimentOn) {
      getShippedShiledExperimentValue()
        .then(setShippedShieldExperimentOn)
        .catch(error)
    }
  }, [shippedShieldExperimentOn])

  return () => shippedShieldExperimentOn
}

export default useShippedShieldExperimentChecker
