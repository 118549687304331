import React from 'react'
import Text from '@csc/dls/Text'
import { AjaxSpinner } from '@/components/Generic'

import {
  initializingApplePayCheckoutMessage,
} from '@/data/applePayMessages'

const applePayModalContentBuilder = (
  modalMessage: string,
): React.ReactNode => {
  if (modalMessage) {
    return (
      modalMessage === initializingApplePayCheckoutMessage
        ? (
          <AjaxSpinner visible />
        )
        : (
          <div className="h-96 flex flex-row items-center justify-center">
            <Text size="lg">{modalMessage}</Text>
          </div>
        )
    )
  }
  return null
}

export default applePayModalContentBuilder
