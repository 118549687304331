import { error } from '@/services/Log'

// Work with both relative and absolute URLs strings as well as URL objects
export const addSearchParamsToUrl = (url: string | URL) => {
  const stringifiedURL = typeof url === 'string' ? url : url.toString()
  const [parsedUrl, parsedQuery = ''] = stringifiedURL.split('?')
  const parsedSearchParams = new URLSearchParams(parsedQuery)

  return (searchParams: Record<string, string>) => {
    if (!Object.keys(searchParams).length) {
      return url
    }
    try {
      Object.entries(searchParams).forEach(([key, value]) => {
        parsedSearchParams.set(key, value)
      })
      return `${parsedUrl}?${parsedSearchParams.toString()}`
    } catch (err) {
      error(`createUrlWithSearchParams::Failed to add search params to URL: ${String(err)}`, err)
      return url
    }
  }
}

export const addSearchTermToURL = (
  url: string | URL,
  searchTerm: string,
) => addSearchParamsToUrl(url)({ search_query: searchTerm })

export default addSearchTermToURL
