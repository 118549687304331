import clsx from 'clsx'
import { IMeganavColumnFields } from '@/types/Meganav'
import MeganavGroup from './MeganavGroup'
import styles from './MeganavColumn.module.scss'

interface MeganavColumnProps {
  column: IMeganavColumnFields;
  onOpenLink?: () => void;
}

const MeganavColumn = ({ column, onOpenLink }: MeganavColumnProps) => {
  const { content = [], devices = [] } = column

  const renderGroups = () => content.map((group) => (
    <MeganavGroup key={group?.internalName} group={group} onOpenLink={onOpenLink} />
  ))

  return (
    <div
      className={clsx('navigation-column', 'mobile-only', styles.meganavColumn, {
        [styles.mobile]: !devices?.length || devices.includes('mobile'),
        [styles.desktop]: !devices?.length || devices.includes('desktop'),
      })}
    >
      {renderGroups()}
    </div>
  )
}

export default MeganavColumn
