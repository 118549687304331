import _findKey from 'lodash/findKey'
import _indexOf from 'lodash/indexOf'
import _get from 'lodash/get'
import _find from 'lodash/find'
import type { Product } from '@/types'
import type { ProductImageSize, ProductSkuAdditionalInfo } from '@/types/Product'
import { checkVariantNameIsSize } from '@/helpers/checkVariantNameIsSize'

export const IMAGE_FALLBACK = 'https://i.zgallerie.com/'
const DEFAULT_SIZE = 'thumbnail'

type MinimumImageProductProps = Pick<Product, 'mainImage' | 'images' | 'additional_info' | 'variants'>

const ProductHelpers = {
  name(this: void, { source: { name } }: { source: { name: string } }) {
    return name.split('~', 1)[0]
  },
  thumbnail(this: void, { source }: {
    source: { images: Array<{ is_thumbnail?: boolean, url_tiny: string }>
    } } | {
    readonly source: {
      readonly images: ReadonlyArray<{
        readonly is_thumbnail?: boolean,
        readonly url_tiny: string
      }>
    } }) {
    const images = source?.images || []
    return ((images.filter(({ is_thumbnail }) => is_thumbnail) || [])[0] || {}).url_tiny
      || IMAGE_FALLBACK
  },
  oversizeCharge({ product, sku }: { product: Product, sku: string }) {
    const charge = (product.additional_info?.[sku] as ProductSkuAdditionalInfo)?.oversize_charge
    return charge && parseFloat(charge).toFixed(2)
  },
  artistName({ product }: { product: Product }) {
    return product.additional_info?.artist_name
  },
  availableAtStores({ product, sku }: { product: Product, sku: string }) {
    return (product.additional_info?.[sku] as ProductSkuAdditionalInfo)?.is_available_at_store
  },
  freeShipping({ product }: { product: Product }) {
    return product.is_free_shipping
  },
  clearance({ product, sku }: { product: Product, sku: string }) {
    return (product.additional_info?.[sku] as ProductSkuAdditionalInfo)?.clearance
  },
  dimensions({ product, sku }: { product: Product, sku: string }) {
    return (product.additional_info?.[sku] as ProductSkuAdditionalInfo)?.dimensions
  },
  image({
    index, size, color, product: {
      mainImage, images, additional_info, variants,
    },
  }: {
    product: MinimumImageProductProps,
    index?: number,
    size: ProductImageSize,
    color?: string | string
  }) {
    const imageSize = size || DEFAULT_SIZE
    const requestedIndex = () => typeof index === 'number'
      && images.sort((a, b) => a.sort_order - b.sort_order)[index]
    const requestedColor = () => {
      if (!color) return null
      const requestedColors: unknown[] = color.split(',')
      const requestedColorVariantSku = _findKey(
        additional_info,
        (skuInfo) => _indexOf(requestedColors, _get(skuInfo, ['color', 0])) >= 0,
      )
      const imageUrl = _get(_find(
        variants,
        (variant) => variant.sku === requestedColorVariantSku,
      ), 'image_url')

      return imageUrl ? {
        [`url_${imageSize}`]: imageUrl,
      } : null
    }

    const getThumbnail = () => {
      const thumbnail = ProductHelpers.thumbnail({ source: { images } })
      return thumbnail !== IMAGE_FALLBACK ? thumbnail : null
    }
    const getImage = () => requestedIndex() || requestedColor() || getThumbnail()
      || images[0] || {}
    return (Array.isArray(images) ? getImage()[`url_${imageSize}`] as string : mainImage)
      || IMAGE_FALLBACK
  },
  getCollectionVariants(product: Product) {
    const { options } = product
    let variants: Product['variants'] = []
    if (options.length === 1 && options[0].display_name === 'Collection') {
      variants = product.variants
    }

    if (!variants.length) {
      return []
    }

    if (variants.every(checkVariantNameIsSize)) {
      variants.sort((a, b) => (
        parseFloat(a.calculated_price) - parseFloat(b.calculated_price)
      ))
    } else {
      variants.sort((a, b) => {
        if (parseFloat(b.calculated_price) > parseFloat(a.calculated_price)) {
          return 1
        } if (parseFloat(a.calculated_price) === parseFloat(b.calculated_price)) {
          if (a.option_values[0].label > b.option_values[0].label) {
            return 1
          }
        }
        return -1
      })
    }

    return variants
  },
  getInitialProductAttribute(product: Product) {
    return product.options.reduce((accumulator: number[], option) => {
      accumulator[option.id] = option.option_values[0].id
      return accumulator
    }, [])
  },

  getProductAttributeBySku(product: Product, sku: string) {
    const optionValues = product.variants.find((variant) => variant.sku === sku)?.option_values
    return optionValues?.reduce((accumulator: number[], optionValue) => {
      accumulator[optionValue.option_id] = optionValue.id
      return accumulator
    }, []) || []
  },
}

export default ProductHelpers
