/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
// TODO: Remove eslint disable comments.
// These lint ignores were added to avoid the need of massive refactor of 332 errors
// This happen when changing the rule from warn to error.
// This aims to avoid making the problem worse.
import { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { IMeganavItemFields } from '@/types/Meganav'
import { MOBILE_COLLAPSIBILITY_THRESHOLD } from '../config'
import MeganavTextLink from './MeganavTextLink'
import MeganavColumn from './MeganavColumn'
import MeganavPromotion from './MeganavPromotion'
import styles from './MeganavItem.module.scss'

const intercept = (event) => ['preventDefault', 'stopPropagation'].forEach((method) => event[method]())

export interface MeganavItemProps {
  item?: IMeganavItemFields,
  isSticky?: boolean;
  onOpenLink?: () => void;
}

const MeganavItem = ({ item, isSticky, onOpenLink }: MeganavItemProps) => {
  const {
    columns = [], title = '', textColor = undefined, promotion = undefined, devices = undefined, url = '', fullWidth = undefined, backgroundColor = undefined,
  } = item || {}

  const [isActive, setIsActive] = useState(false)
  const [catchClick, setCatchClick] = useState(false)
  const [deactivateOutside, setDeactivateOutside] = useState(null)

  const deactivationListener = useCallback((event) => {
    if (isActive && deactivateOutside) {
      let locality = event.target
      while (locality) {
        if (locality === deactivateOutside) {
          return
        }
        locality = locality.parentNode
      }
      intercept(event)
      setIsActive(false)
    }
  }, [deactivateOutside, isActive, setIsActive])

  const toggle = useCallback((event) => {
    intercept(event)
    setIsActive((current) => !current)
  }, [setIsActive])

  useEffect(() => {
    const resizeListener = () => setCatchClick(window.innerWidth < MOBILE_COLLAPSIBILITY_THRESHOLD)
    document.addEventListener('click', deactivationListener)
    window.addEventListener('resize', resizeListener)
    resizeListener()
    return () => {
      document.removeEventListener('click', deactivationListener)
      window.removeEventListener('resize', resizeListener)
    }
  }, [deactivationListener])

  const usableColumns = columns.filter((fields) => fields.content.length)

  const renderColumns = () => usableColumns.map((column) => (
    <MeganavColumn
      key={column?.internalName}
      column={column}
      onOpenLink={onOpenLink}
    />
  ))

  const renderPromotion = () => {
    if (!promotion) {
      return null
    }

    return (
      <MeganavPromotion
        id={`nav-${(/^\w+/.exec(title))![0]?.toLowerCase()}`}
        promotion={promotion}
      />
    )
  }
  return (
    <li
      className={clsx(
        'navigation-main-li',
        styles.meganavItem,
        {
          'is-open': isActive,
          'fp_second-navigation-bar-nav-sale': String(title || '').toLowerCase() === 'sale',
          'fp_second-navigation-bar-top-container-dropdown-fullwidth-position': !!fullWidth,
          [styles.mobile]: !devices?.length || devices.includes('mobile'),
          [styles.desktop]: !devices?.length || devices.includes('desktop'),
        },
      )}
      ref={setDeactivateOutside as any}
    >
      <MeganavTextLink
        name={title}
        url={url}
        inner={(content) => <span>{content}</span>}
        onClick={catchClick ? toggle : undefined}
        style={{ color: textColor }}
        testId={`second-${String(title || '').toLowerCase()}`}
      />
      <div
        style={{ backgroundColor }}
        className={clsx(
          'custom-navigation-dropdown',
          !!fullWidth && isSticky && styles.fullWidthDropDown,
          {
            'fp_second-navigation-bar-top-container-dropdown-fullwidth': !!fullWidth,
            fp_D_TopNav_SecondNav_Menu_inspiration: String(title || '').toLowerCase() === 'inspiration',
          },
        )}
      >
        <div className={clsx('navigation-row', {
          [styles.inspiration]: String(title || '').toLowerCase() === 'inspiration',
          'fp_second-navigation-bar-top-container-dropdown-inspiration': String(title || '').toLowerCase() === 'inspiration',
          'fp_second-navigation-bar-row': String(title || '').toLowerCase() !== 'inspiration',
        })}
        >
          {renderColumns()}
          {renderPromotion()}
        </div>
      </div>
    </li>
  )
}

export default MeganavItem
