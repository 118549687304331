/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import { useIsMobile } from '@/hooks/useDeviceDetect'
import clsx from 'clsx'
import { useIsUserSignedIn } from '@/components/Sitewide/Navbar/NavigationContext'
import { error } from '@/services/Log'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { UserAccountIcon } from '../icons'
import styles from './styles.module.scss'

export const SIGN_IN_URL = '/login'

const UserLogin = () => {
  const isMobile = useIsMobile()
  const [isOpenedMobilePopup, setIsOpenedMobilePopup] = useState(false)
  const { isUserSignedIn } = useIsUserSignedIn()

  const handleLogoutClick = async () => {
    const [{ default: Axios }] = await allPromisesWithRetries(() => [
      import('axios'),
    ])
    await Axios.get('/sign-out')
    try {
      localStorage.clear()
    } catch (err) {
      error('Failed to clear localStorage', err)
    }
    window.location.href = SIGN_IN_URL
  }

  const handleLoginClick = () => {
    try {
      localStorage.setItem('loginRedirectLink', JSON.stringify(window.location.href))
    } catch (err) {
      error('Failed to set localStorage', err)
    }
    window.location.href = SIGN_IN_URL
  }

  const handleLogout = () => {
    handleLogoutClick()
      .catch((err) => {
        error('Failed to logout', err)
      })
  }

  const renderSignedIn = () => {
    if (isMobile) {
      return (
        <li className={clsx('navUser-action', 'mobile', 'userAccount', styles.root)}>
          <div className="navUser-action">
            <button
              type="button"
              onClick={() => setIsOpenedMobilePopup((val) => !val)}
              className={styles.button}
            >
              <img
                src={UserAccountIcon}
                alt="user account icon"
                className={styles.logo}
              />
            </button>
          </div>
          {isOpenedMobilePopup && (
            <div className="dropdown-account-mobile">
              <img
                src="https://cdn11.bigcommerce.com/s-u6ub4rctfv/content/images/up-arrow-white.PNG"
                alt="user account icon"
              />
              <div>
                <a href="/my-account">My Account</a>
              </div>
              <div>
                <button
                  type="button"
                  onClick={handleLogout}
                >
                  SIGN OUT

                </button>
              </div>
            </div>
          )}
        </li>
      )
    }
    return (
      <>
        <li className="navUser-item navUser-item--account">
          <a className="navUser-action" href="/account" data-testid="header-myAccount">
            <img src={UserAccountIcon} alt="user account icon" />
            <span>My Account</span>
          </a>
        </li>
        <li className={clsx('navUser-item desktop navUser-item--account', styles.signOut)}>
          <a
            id="topNav-signOut"
            data-testid="header-signOut"
            className="navUser-action"
            aria-hidden="true"
            title="sign out"
            onClick={handleLogout}
          >
            <span>Sign Out</span>
          </a>
        </li>
      </>
    )
  }

  const renderNotSignedIn = () => (
    <li className="navUser-item navUser-item--account">
      <a
        className="navUser-action"
        id="topNav-signIn"
        data-testid="header-signIn"
        aria-hidden="true"
        onClick={handleLoginClick}
      >
        <img
          src={UserAccountIcon}
          alt="user account icon"
        />
        <span>Sign in</span>
      </a>
    </li>
  )

  return isUserSignedIn ? renderSignedIn()
    : renderNotSignedIn()
}

export default UserLogin
