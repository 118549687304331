import { useEffect, useState } from 'react'
import { isMobile as isStaticMobile } from 'react-device-detect'

const useIsMobile = () => {
  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    // TODO: Fix the implementation. Sometimes the library doesn't work correctly
    setMobile(isStaticMobile || window.innerWidth <= 800)
  }, [])

  return mobile
}

export { useIsMobile }
