const ZGoldLogo = () => (
  <svg
    version="1.1"
    baseProfile="tiny"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 20 20"
    overflow="visible"
    xmlSpace="preserve"
  >
    <g>
      <path
        fill="#1A1A1A"
        d="M8.1,5h3.6l-2.9,9.6h3.3v0.5h-4l2.8-9.5H8.1V5z M7.3,4.2v2.2h2.6L7,15.8H13v-2.1H9.9l2.9-9.6H7.3z"
      />
      <rect x="9.6" y="1.4" fill="#1A1A1A" width="0.8" height="2.3" />
      <rect x="9.6" y="16.4" fill="#1A1A1A" width="0.8" height="2.3" />
    </g>
    <g>
      <path
        fill="#1A1A1A"
        d="M10,20C4.5,20,0,15.5,0,10S4.5,0,10,0s10,4.5,10,10S15.5,20,10,20z M10,0.2c-5.4,0-9.8,4.4-9.8,9.8s4.4,9.8,9.8,9.8s9.8-4.4,9.8-9.8S15.4,0.2,10,0.2z"
      />
    </g>
  </svg>
)

export default ZGoldLogo
