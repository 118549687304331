/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
// TODO: Remove eslint disable comments.
// These lint ignores were added to avoid the need of massive refactor of 332 errors
// This happen when changing the rule from warn to error.
// This aims to avoid making the problem worse.
import { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { IMeganavColumnContentFields } from '@/types/Meganav'
import MeganavTextLink from './MeganavTextLink'
import { MOBILE_TOGGLE_CONDITION } from '../config'
import styles from './MeganavGroup.module.scss'

const intercept = (arg) => ['preventDefault', 'stopPropagation']
  .forEach((method) => arg[method]())

interface MeganavGroupProps {
  group: IMeganavColumnContentFields,
  onOpenLink?: ((e: unknown) => void) | (() => void),
}

const MeganavGroup = ({ group, onOpenLink }: MeganavGroupProps) => {
  const { mainLink, links = [], devices = [] } = group
  const [isActive, setIsActive] = useState(false)
  const [deactivateOutside, setDeactivateOutside] = useState(null)
  const deactivationListener = useCallback((event) => {
    if (isActive && deactivateOutside && MOBILE_TOGGLE_CONDITION()) {
      let locality = event.target
      while (locality) {
        if (locality === deactivateOutside) {
          return
        }
        locality = locality.parentNode
      }
      intercept(event)
      setIsActive(false)
    }
  }, [isActive, setIsActive, deactivateOutside])

  const toggle = useCallback((event) => {
    if (MOBILE_TOGGLE_CONDITION()) {
      intercept(event)
      setIsActive((current) => !current)
    }
  }, [setIsActive])

  useEffect(() => {
    document.addEventListener('click', deactivationListener)
    return () => document.removeEventListener('click', deactivationListener)
  }, [deactivationListener])

  const renderLinks = () => [mainLink, ...links].map((link, index) => (
    link && (
    <li
      key={`${link?.internalName}-${link.title}-${link?.url}`}
      className={clsx(styles.meganavTextLinkContainer, {
        'has-submenu': index === 0,
        [styles.mobile]: !link.devices?.length || link.devices.includes('mobile'),
        [styles.desktop]: !link.devices?.length || link.devices.includes('desktop'),
        [styles.bold]: link?.boldText,
        [styles.hasLink]: !!link.url,
      })}
      onClick={index === 0 && links.length ? toggle : undefined}
      aria-hidden="true"
    >
      <MeganavTextLink
        url={link.url || ''}
        name={link.title}
        newTab={!!link.newTab}
        style={{ color: link.textColor }}
        onOpenLink={onOpenLink}
      />
    </li>
    )
  ))

  return (
    <ul
      ref={setDeactivateOutside as any}
      className={clsx(styles.meganavGroup, {
        'submenu-open': isActive,
        [styles.mobile]: !devices.length || devices.includes('mobile'),
        [styles.desktop]: !devices.length || devices.includes('desktop'),
      })}
    >
      {renderLinks()}
    </ul>
  )
}

export default MeganavGroup
