import Link from 'next/link'

import Exclude from '@/exclude'
import { CSSProperties } from 'react'

const PATTERN = /^\/c-(\d+)-([a-z-]+)/

// HACK: Force a complete reload of the page for these IDs
//    in order to load from BC not SSR see : WDR-82
const RELOAD_NAV_CATS = ['319', '36', '54', '55', '61']

const LocalLink = ({
  content,
  legacyId,
  slug,
  url,
  newTab,
  style = {},
  testId,
  onOpenLink,
}: {
  content: React.ReactNode
  legacyId: string
  slug: string
  url: string
  newTab?: boolean
  style: CSSProperties
  testId?: string
  onOpenLink: ((e: unknown) => void) | (() => void)
}) => (
  <Link
    href={{
      pathname: '/category',
      query: { slug, legacyId },
    }}
    as={url}
    prefetch={false}
  >
    <a
      onClick={(event) => {
        onOpenLink?.(event)
      }}
      data-testid={testId}
      style={style}
      href={url}
      target={newTab ? '_blank' : undefined}
      rel="noreferrer"
    >
      {content}
    </a>
  </Link>
)

const NextAnchor = ({
  content,
  legacyId,
  slug,
  url,
  newTab,
  style = {},
  testId,
  onOpenLink,
}: {
  content: React.ReactNode
  legacyId: string
  slug: string
  url: string
  newTab?: boolean
  style: CSSProperties
  testId?: string
  onOpenLink: ((e: unknown) => void) | (() => void)
}) => (RELOAD_NAV_CATS.includes(legacyId) ? (
  <a
    data-testid={testId}
    style={style}
    href={url}
    target={newTab ? '_blank' : undefined}
    rel="noreferrer"
  >
    {content}
  </a>
) : (
  <LocalLink
    onOpenLink={(event) => {
      onOpenLink?.(event)
    }}
    testId={testId}
    content={content}
    newTab={newTab}
    legacyId={legacyId}
    slug={slug}
    url={url}
    style={style}
  />
))

const NormalAnchor = ({
  content, onClick, url, style = {}, newTab, testId,
}: {
  content: React.ReactNode
  onClick?: (e: unknown) => void
  url: string
  style: CSSProperties
  newTab?: boolean
  testId?: string
}) => (onClick ? (
  <a
    data-testid={testId}
    onClick={(event) => {
      onClick(event)
    }}
    href={url}
    style={style}
    target={newTab ? '_blank' : undefined}
    rel="noreferrer"
  >
    {content}
  </a>
) : (
  <a
    data-testid={testId}
    href={url}
    data-regular-link
    style={style}
    target={newTab ? '_blank' : undefined}
    rel="noreferrer"
  >
    {content}
  </a>
))

const MeganavTextLink = ({
  inner,
  name,
  onClick = () => {},
  url,
  style = {},
  newTab,
  testId,
  onOpenLink = () => {},
}: {
  inner?: (name: string) => React.ReactNode
  name: string
  onClick?: (e: unknown) => void
  url: string
  style: CSSProperties
  newTab?: boolean
  testId?: string
  onOpenLink?: ((e: unknown) => void) | (() => void)
}) => {
  const [legacyId, slug] = (url?.match?.(PATTERN) || []).slice(1)
  const content = typeof inner === 'function' ? inner(name) : name
  const attemptNext = (slug && typeof onClick !== 'function' && !Exclude.includes(url))
  const ComponentType = attemptNext ? NextAnchor : NormalAnchor
  return (
    <ComponentType
      onOpenLink={onOpenLink}
      content={content}
      legacyId={legacyId}
      onClick={onClick}
      slug={slug}
      url={url}
      style={style}
      newTab={newTab}
      testId={testId}
    />
  )
}

export default MeganavTextLink
