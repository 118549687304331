import { trackEvent } from '@/services/Tracking/GTAG/trackEvent'
import { convertProductToGA4Item } from '@/services/Tracking/GTAG/convertProductToGA4Item'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { GA4_GROUP } from '@/data/constants'
import type { ProductESV1 } from '@/types/ElasticSearch/SearchAPIResponse'
import type { StandardCart, StandardLineItem } from '@/types/ShopFront/CheckoutStandards'
import type { RequiredProductAttributes } from '@/services/Tracking/GTAG/convertProductToGA4Item'
import { FEATURE_TOGGLES } from '@/services/Configuration'
import { getProductName } from '@/services/Tracking/helpers'
import { error } from '@/services/Log'

const {
  facebookConversionApiEnabled: FACEBOOK_CONVERSION_TOGGLE,
} = FEATURE_TOGGLES

type Category = {
  name: string,
  id: string | undefined,
}

export type User = {
  email: string;
  phone: string;
  zip?: string;
}

type MinimumCartLineItem = Pick<StandardLineItem,
'originalPrice' |
'name' |
'sku' |
'quantity' |
'variantId' |
'extendedListPrice' |
'isExtendItem' |
'discounts' |
'salePrice' |
'originalName'
>

type MinimumCartLineItemCustom = Omit<MinimumCartLineItem,
'originalPrice' |
'variantId' |
'salePrice' |
'discounts' |
'originalName'
>

type AnalyticsProps = {
  onItemDetailsView: (product: RequiredProductAttributes, user?: User) => Promise<void>;
  onCartView: (lineItems: Array<(MinimumCartLineItem | MinimumCartLineItemCustom)>,
    cartAmount: number, user?: User) => Promise<void>;
  onItemAddToCart: (product: RequiredProductAttributes, user?: User) => Promise<void>;
  onItemRemoveFromCart: (product: RequiredProductAttributes) => void;
  onAddingShippingInfo: (cart: StandardCart) => void;
  onItemListView: (products: ProductESV1[], category: Category, user?: User) => Promise<void>;
  onItemSelect: (product: (RequiredProductAttributes)) => void;
}

export const Analytics: AnalyticsProps = {

  onItemDetailsView: async (product, user) => {
    const [
      { trackTikTokPixelAndIdentifyUser },
      { Facebook },
      { listrakProductBrowse },
    ] = await allPromisesWithRetries(() => [
      import('@/services/TikTokPixel'),
      import('@/services/Tracking/Facebook/Facebook'),
      import('@/services/Tracking/Listrak'),
    ])

    // GA4
    trackEvent('view_item', {
      currency: 'USD',
      value: product.salePrice,
      items: [convertProductToGA4Item(product)],
      user,
    }, GA4_GROUP)

    // TikTok
    trackTikTokPixelAndIdentifyUser('ViewContent', product, user)

    // Facebook
    if (FACEBOOK_CONVERSION_TOGGLE) {
      const {
        sku, category = 'N/A', name, salePrice,
      } = product

      const viewContentPayload = {
        user,
        content_ids: [sku],
        content_category: category,
        content_name: getProductName(name),
        contents: [{ id: sku, quantity: 1, item_price: salePrice }],
        value: product.salePrice,
      }
      Facebook.viewContent(viewContentPayload)
    }

    // Listrak
    listrakProductBrowse(product?.sku)
  },

  onItemListView: async (products, category, user) => {
    const [
      { default: kebabCase },
    ] = await allPromisesWithRetries(() => [
      import('lodash/kebabCase'),
    ])

    const convertedItems = products.map((product, index:number) => convertProductToGA4Item({
      name: product.name,
      sku: product.variants[0].sku,
      price: product.variants[0].price,
      salePrice: product.variants[0].calculated_price !== 0
        ? product.variants[0].calculated_price
        : product.variants[0].price,
      brand: product.brand,
      variantId: String(product.variants[0].id),
      category: kebabCase(category.name),
      categoryId: category.id,
      position: index,
      quantity: 1,
    }))

    // GA4
    trackEvent('view_item_list', {
      item_list_id: category.id,
      item_list_name: category.name,
      items: convertedItems,
      user,
    }, GA4_GROUP)
  },

  onItemSelect: (product) => {
    // GA4
    trackEvent('select_item', {
      item_list_id: product.categoryId,
      item_list_name: product.category,
      items: [convertProductToGA4Item(product)],
    }, GA4_GROUP)
  },

  onItemAddToCart: async (product, user?: User) => {
    const [
      { trackTikTokPixelAndIdentifyUser },
      { Facebook },
    ] = await allPromisesWithRetries(() => [
      import('@/services/TikTokPixel'),
      import('@/services/Tracking/Facebook/Facebook'),
    ])
    // GA4
    trackEvent('add_to_cart', {
      currency: 'USD',
      value: product.salePrice * (product.quantity ?? 1),
      items: [convertProductToGA4Item(product)],
      user,
    }, GA4_GROUP)

    try {
      trackTikTokPixelAndIdentifyUser('AddToCart', product, user)
    } catch (e) {
      error('Failed to send analytics to the TikTok.', e)
    }

    if (FACEBOOK_CONVERSION_TOGGLE) {
      const {
        sku, category = 'N/A', name, salePrice, quantity,
      } = product

      const addToCartPayload = {
        user,
        content_ids: [sku],
        content_category: category,
        content_name: getProductName(name),
        contents: [{ id: sku, quantity: quantity ?? 1, item_price: salePrice }],
        value: product.salePrice,
      }
      Facebook.addToCart(addToCartPayload)
    }
  },

  onItemRemoveFromCart: (product) => {
    // GA4
    trackEvent('remove_from_cart', {
      currency: 'USD',
      value: product.salePrice * (product.quantity ?? 1),
      items: [convertProductToGA4Item(product)],
    }, GA4_GROUP)
  },

  onCartView: async (lineItems, cartAmount, user) => {
    const [
      { getActualPrice },
    ] = await allPromisesWithRetries(() => [
      import('@/helpers/checkoutHelpers/getActualPrice'),
    ])
    let index = -1
    const convertedItems = lineItems
      .filter((item) => !item.isExtendItem)
      .map((item: MinimumCartLineItem) => {
        const actualPrice = getActualPrice<MinimumCartLineItem>(item)
        const basePrice = item.originalPrice ?? actualPrice
        index += 1
        return convertProductToGA4Item({
          name: item.originalName || item.name,
          sku: item.sku,
          price: basePrice,
          salePrice: actualPrice,
          variantId: String(item.variantId),
          category: undefined,
          quantity: item.quantity,
          position: index,
        })
      })

    lineItems.filter((item) => item.isExtendItem).forEach((item) => {
      index += 1
      convertedItems.push(convertProductToGA4Item(
        {
          name: item.name,
          sku: item.sku,
          price: item.extendedListPrice,
          salePrice: item.extendedListPrice,
          variantId: 'N/A',
          category: undefined,
          quantity: item.quantity,
          position: index,
        },
      ))
    })

    trackEvent('view_cart', {
      currency: 'USD',
      value: cartAmount,
      items: convertedItems,
      user,
    }, GA4_GROUP)
  },

  onAddingShippingInfo: (cart) => {
    const convertedItems = cart.lineItems.map((
      {
        name,
        sku,
        originalPrice,
        originalName,
        salePrice,
        quantity,
        variantId,
        brand,
      }, index,
    ) => convertProductToGA4Item({
      sku,
      brand,
      price: originalPrice,
      salePrice,
      variantId: String(variantId),
      category: undefined,
      position: index,
      quantity,
      name: originalName ?? name,
    }))

    trackEvent('add_shipping_info', {
      currency: 'USD',
      value: cart.subtotal,
      items: convertedItems,
    }, GA4_GROUP)
  },
}

export default Analytics
