import { IMeganav } from '@/types/Meganav'
import MeganavItem from './MeganavItem'

interface MeganavProps {
  meganav: IMeganav,
  pageType?: string;
  className?: string;
  isSticky?: boolean;
  onOpenLink?: () => void;
}

const Meganav = (props: MeganavProps) => {
  const {
    meganav, pageType, className = '', isSticky, onOpenLink,
  } = props
  const renderItems = () => meganav?.meganavItems
    .filter((item) => item?.title?.toLowerCase() !== 'inspiration')
    .map((item) => (
      <MeganavItem
        key={item?.title}
        isSticky={isSticky}
        item={item}
        onOpenLink={onOpenLink}
      />
    ))

  return (
    <div style={{ display: pageType === 'cart' ? 'none' : undefined }} className={className}>
      <ul className="custom-navigation-ul">
        {renderItems()}
      </ul>
    </div>
  )
}

export default Meganav
