import { useEffect, useState, ChangeEventHandler } from 'react'
import { removeSpecialChars } from '@/helpers/common'
import Keys from '@/helpers/Keys'
import { useDebounce } from 'react-use'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import type {
  SearchSuggestResultsBodyType,
  SearchSuggestResultsType,
  SearchSuggestResultsCategoryType,
  SearchSuggestResultsInspirationType,
} from '@/types/ElasticSearch/SuggestRuntimeType'
import { error } from '@/services/Log'
import CategoryResult from './CategoryResult'
import InspirationResult from './InspirationResult'
import ProductResult from './ProductResult'
import SearchIcon from './search.svg'
import type {
  RenderableSuggestResult,
  RenderableSuggestResultCategory,
} from './results.types'

const domId = 'search_query'

// The upstream file is JS at time of writing - assign type to ingress node here.

const startsWithIntegerCategory = (category: string) => /^\/c-\d+/.test(category)
const isRenderableCategory = ({
  source: {
    custom_url: {
      url = '',
    },
  },
}: SearchSuggestResultsCategoryType) => startsWithIntegerCategory(url)
&& (
  // DMP Index will provice categories via an allow list from business
  // we will trust the DMP index to provide the correct categories
  true
)
type CategoryResultGroupingByName = {
  [key: string]: SearchSuggestResultsCategoryType[]
}
const renderableWithDistinguishableNames = (
  category: readonly SearchSuggestResultsCategoryType[],
): RenderableSuggestResultCategory[] => {
  /*
  Returns distinguishable names for categories that have the same
  name using it's parent name.
  1. Group the category suggestions by the source name
  2. Check each group if they have more than 1 member
    a. If 1 member then do nothing
    b. If more than 1 member
      i. if category path has more than 1 parent then modify name
  */
  const groups: CategoryResultGroupingByName = category
    .reduce((h: CategoryResultGroupingByName, item: SearchSuggestResultsCategoryType) => ({
      ...h,
      [item.source.name]: (h[item.source.name] || []).concat(item),
    }), {})
  const groupDisambuatingReducer = (arr: RenderableSuggestResultCategory[], key: string) => {
    const nameGroup = groups[key] || []
    const nameGroupLength = nameGroup.length
    const mappedNameGroup: RenderableSuggestResultCategory[] = nameGroup.map((cat) => ({
      ...cat,
      name: nameGroupLength === 1
        ? cat.source.name
        : cat.source.path.slice(-2).join(' '),
    }))
    return [...arr, ...mappedNameGroup]
  }
  const disambiguatedGroups: RenderableSuggestResultCategory[] = Object
    .keys(groups)
    .reduce(groupDisambuatingReducer, [])
  return disambiguatedGroups.filter(isRenderableCategory)
}

export const renderableResults = ({
  category: categoriesHits,
  inspiration,
  inspiration_static: inspirationStatic,
  product,
}: SearchSuggestResultsType): RenderableSuggestResult => ({
  category: renderableWithDistinguishableNames(categoriesHits),
  inspiration: inspiration.concat(inspirationStatic)
    .filter((ins: SearchSuggestResultsInspirationType) => Object.prototype.hasOwnProperty.call(ins?.source, 'custom_url'))
    .slice(0, 3),
  product,
})

const anyResults = ({
  category,
  inspiration,
  product,
}: RenderableSuggestResult): boolean => [
  category,
  inspiration,
  product,
].some((piece) => piece.length)

type InputOutputForSearch = {
  readonly query: string,
  readonly response: SearchSuggestResultsBodyType,
}

const emptySearchResult: RenderableSuggestResult = {
  category: [],
  inspiration: [],
  product: [],
}

const emptyInputOutputForSearch: InputOutputForSearch = {
  query: '',
  response: {
    success: true,
    error: null,
    data: {
      query: '',
      category: [],
      inspiration: [],
      inspiration_static: [],
      product: [],
    },
  },
}

const SearchBox = () => {
  const [query, setQuery] = useState<string>('')
  const [searchResult, setSearchResult] = useState<RenderableSuggestResult>(emptySearchResult)
  const [shouldBeVisible, setShouldBeVisible] = useState(false)
  const [
    mostRecentResponsePair,
    setMostRecentResponsePair,
  ] = useState<InputOutputForSearch>(emptyInputOutputForSearch)

  const setValue: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => setQuery(value)

  const [, cancel] = useDebounce(() => {
    const update = async (): Promise<void> => {
      const [{ suggest }] = await allPromisesWithRetries(() => [
        import('@/services/Search/suggest'),
      ])
      const response = await suggest(removeSpecialChars(query.trim()))
      if (response) setMostRecentResponsePair({ query, response })
    }
    setShouldBeVisible(!!query)
    if (query) {
      // explicitly fire and forget relative to this closure
      update()
        .then(() => {})
        .catch(() => {})
    }
  }, 1000, [
    query,
  ])

  const onKeyUp = ({ keyCode }) => {
    if (keyCode === Keys.ESCAPE) {
      setShouldBeVisible(false)
      cancel()
    }
  }

  const onFocus = () => setShouldBeVisible(anyResults(searchResult))
  const onFormSubmit = async () => {
    try {
      const [
        { onSearchTermRegister },
      ] = await allPromisesWithRetries(() => [
        import('@/services/Tracking/Analytics/onSearchTermRegister'),
      ])

      onSearchTermRegister(query)
    } catch (e) {
      error('SearchBox::onFormSubmit::Failed to register search term for analytics ')
    }
  }

  useEffect(() => {
    if (mostRecentResponsePair.query === query && mostRecentResponsePair.response.success) {
      const renderable = renderableResults(mostRecentResponsePair.response.data)
      setSearchResult(renderable)
      setShouldBeVisible(anyResults(renderable))
    }
  }, [
    mostRecentResponsePair,
    query,
  ])

  useEffect(() => {
    const handleClick = () => setShouldBeVisible(false)
    document.addEventListener('click', handleClick)
    return () => document.removeEventListener('click', handleClick)
  }, [
    setShouldBeVisible,
  ])

  return (
    <div>
      <form
        id="quick-search-form"
        className="form"
        action="/search.php"
        onSubmit={() => {
          onFormSubmit()
        }}
      >
        <fieldset className="form-fieldset">
          <div className="form-field">
            <label className="is-srOnly" htmlFor={domId}>Quick search</label>
            <input
              style={{
                color: '#333',
                border: '1px solid #999',
                backgroundColor: '#fff',
                padding: '5px 27px 5px 10px',
              }}
              className="form-input"
              type="text"
              id={domId}
              data-search-quick
              name={domId}
              data-error-message="Search field cannot be empty."
              placeholder="Search"
              autoComplete="off"
              value={query}
              onKeyUp={onKeyUp}
              onChange={setValue}
              onFocus={onFocus}
              data-testid="second-search"
            />
            <button style={{ display: 'block' }} type="submit" data-testid="second-search-submit">
              <img src={SearchIcon} alt="Search Submit" className="ae-img" />
            </button>
          </div>
        </fieldset>
      </form>
      {shouldBeVisible && (
        <div id="TypeAheadResult" style={{ display: 'block' }}>
          <div className="typehead">
            <div className="arrow" />
            <ul>
              {(
                searchResult.category.length >= searchResult.inspiration.length
              ) && (
                searchResult.category.length >= 1
              ) && (
                <li>
                  Categories
                  <ul>
                    {searchResult.category.map((category) => (
                      <CategoryResult
                        key={JSON.stringify(category)}
                        category={category}
                        searchTerm={query}
                      />
                    ))}
                  </ul>
                </li>
              )}
              {!!searchResult?.product?.length && (
                <li>
                  <div className="see_all">
                    <a href={`/search.php?search_query=${encodeURIComponent(query)}`}>See All</a>
                  </div>
                  Products
                  <ul>
                    {searchResult.product.map((product) => (
                      <ProductResult
                        key={JSON.stringify(product)}
                        product={product}
                        searchTerm={query}
                      />
                    ))}
                  </ul>
                </li>
              )}
              {!!searchResult?.inspiration?.length && (
                <li>
                  Inspiration
                  <ul>
                    {searchResult?.inspiration
                      .map((inspiration: SearchSuggestResultsInspirationType) => (
                        <InspirationResult
                          key={JSON.stringify(inspiration)}
                          inspiration={inspiration}
                          searchTerm={query}
                        />
                      ))}
                  </ul>
                </li>

              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}

export default SearchBox
