import clsx from 'clsx'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { IMeganavColumnImageFields } from '@/types/Meganav'
import { useMemo, useRef, useState } from 'react'
import { useIntersection } from 'react-use'
import styles from './MeganavPromotion.module.scss'

interface MeganavPromotionProps {
  id: string,
  promotion: IMeganavColumnImageFields
}

const MeganavPromotion = ({ id, promotion }: MeganavPromotionProps) => {
  const {
    title, url, imageUrl, body, cta, newTab,
  } = promotion

  const ref = useRef<HTMLDivElement | null>(null)
  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  })

  const isVisible = useMemo(() => !!intersection?.intersectionRatio === true, [intersection])
  const [wasImageOpened, setWasImageOpened] = useState(false)

  const image = useMemo(() => {
    if ((!wasImageOpened && isVisible) || wasImageOpened) {
      setWasImageOpened(true)
      return (
        <img loading="lazy" src={imageUrl} alt="promotion" className={styles.image} />
      )
    }
    return <div className={styles.image} />
  },
  [wasImageOpened, isVisible, imageUrl])

  return (
    <div
      id={id}
      className={clsx('navigation-column', 'nav-promotions-container', styles.meganavPromotion)}
    >
      <div ref={ref}>
        <a href={url} target={newTab ? '_blank' : undefined} rel="noreferrer">
          {image}
        </a>
        {!!title && (
          <div className={styles.title}>
            <a href={url}>{documentToReactComponents(title)}</a>
          </div>
        )}
        {!!body && (
          <div className={styles.description}>
            {documentToReactComponents(body)}
          </div>
        )}
        <div className={styles.callToAction}>
          <a href={url}>{documentToReactComponents(cta)}</a>
        </div>
      </div>
    </div>
  )
}

export default MeganavPromotion
