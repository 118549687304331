// TODO: Remove eslint disable comments.
import dynamic from 'next/dynamic'

export type ShippedConfig = {
  storeHash: string,
  environment?: string,
}

export type ShippedProduct = {
  mandatory: boolean,
  price: string,
  productDescription: Array<string>,
  productId: number,
  productImageURL: string,
  productTitle: string,
  type: string,
  variantId: number,
}

export type ShippedDetails = {
  isSelected?: boolean,
  totalFee?: string,
  shippedProducts?: Array<ShippedProduct>,
  isCartEligible?: boolean | null,
}

export type ShippedWidgetProps = {
  shippedConfig: ShippedConfig,
  onChange?: (details: ShippedDetails) => void,
  onInit?: (details: ShippedDetails) => void,
  isCartEligible?: boolean | null,
  // cartItems mustn't include any shippedshield related properties
  cartItems?: unknown, // there's yet no clear definition of what this prop should be
}

// as shippedshield sdk is using client side APIs, we need to load the sdk only on client side
const ShippedShield = dynamic<ShippedWidgetProps>(
  import('shipped-suite-bigcommerce-react-client-sdk')
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .then(({ ShippedWidget }) => ShippedWidget),
  { ssr: false },
)

export default ShippedShield
