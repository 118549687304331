// These are exclusions from routable Next.js pages.
// Under fractional adoption, these are pages that
// match the normal routing or URL parameters for the app, but
// need to be caught but the traffic manager,
// and thus should be treated as concrete page loads.


// Regex is tested against the `location.pathname` portion of a URI.
// Functions recieve `location.pathname` and should return a truthy value to be exluded.
// Strings must be an EXACT match (not prefix like nginx), use regex for prefix.

// Concretely
// (1) exclude.js is evaulated, an a normal <a> tag is used for any caught values
// (2) routes.js is evaluated, and a Link tag is used for any caught values.
// (3) The final behavior is <a> tags for any urls meeting neither (1) nor (2).
module.exports = [
  '/c-furniture-dining-room',
  '/c-furniture-bedroom',
  '/c-sazan-home-makeover',
  '/c-furniture-dining-room'
]
