import { log } from '@/services/Log'
import { useEffect, useState } from 'react'

export const useCanUseApplePayWithApplePaySession = () => {
  const [canUseApplePay, setCanUseApplePay] = useState(false)
  useEffect(() => {
    log('useCanUseApplePayWithApplePaySession using window.ApplePaySession to check if ApplePaySession is available')
    if (window.ApplePaySession) {
      setCanUseApplePay(ApplePaySession?.canMakePayments())
    }
  }, [])

  return {
    canUseApplePay,
    paymentRequest: null,
  }
}

export default useCanUseApplePayWithApplePaySession
