import { addSearchTermToURL } from '@/helpers/searchHelpers/addSearchTermToURL'
import { CategoryResultProps } from './results.types'

const CategoryResult: React.FC<CategoryResultProps> = ({
  searchTerm,
  category: {
    name,
    source: {
      custom_url: {
        url,
      },
    },
  },
}) => (
  <li
    className="autocomplete-result autocomplete-category-result"
    data-name={name}
  >
    <a
      className="category_list"
      href={addSearchTermToURL(url, searchTerm).toString()}
      data-ae-blurbtype="link"
    >
      <div className="divhover">{name}</div>
    </a>
  </li>
)

export default CategoryResult
