/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
// TODO: Remove eslint disable comments.
// These lint ignores were added to avoid the need of massive refactor of 332 errors
// This happen when changing the rule from warn to error.
// This aims to avoid making the problem worse.
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'

export const clearShippedShieldFromCart = async () => {
  // shippedshield team provided this method to clear shippedshield item from cart when it's empty
  const [{ CartState }] = await allPromisesWithRetries(() => [import('shipped-suite-bigcommerce-react-client-sdk')])

  const cartState = new CartState()
  await cartState.removeShippedProductsIfPresentInCart()
}

export default clearShippedShieldFromCart
