import { isFeatureEnabled } from '@/helpers/isFeatureEnabled'
import { useCanUseApplePayWithStripe } from '@/hooks/useCanUseApplePayWithStripe'
import { useCanUseApplePayWithApplePaySession } from '@/hooks/useCanUseApplePayWithApplePaySession'

const useCanUseApplePay = (
  isFeatureEnabled('isStripeApplePayEnabled')
    ? useCanUseApplePayWithStripe
    // Initial Aple Pay Flow with cybersource via csc-payments
    : useCanUseApplePayWithApplePaySession
)

export default useCanUseApplePay
