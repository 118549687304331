import React, {
  Dispatch, SetStateAction, useCallback, useEffect, useState,
} from 'react'
import { useCustomer } from '@/hooks/customer'
import { Cart } from '@/services/Cart'
import { useFetchCart, useCart } from '@/hooks/cart'
import clsx from 'clsx'
import { error, log } from '@/services/Log'
import Analytics from '@/services/Tracking/Analytics'
import type { StandardCart } from '@/types/ShopFront/CheckoutStandards'
import { CartIcon } from '../icons'
import PreviewContent, { PreviewContentProps } from './PreviewContent'
import './CartPreview.module.scss'

export interface CartPreviewProps {
  pageType: string;
  styles?: {
    li?: string;
    quantity?: string;
    button?: string;
    icon?: string;
    label?: string;
    preview?: PreviewContentProps['styles'];
  };
  onClick?: (isOpened: boolean) => void;
  setPreviewVisible: (boolean) => void | Dispatch<SetStateAction<boolean>>
  previewVisible: boolean;
}

const sumOfQuantityOfItems = (items: {
  quantity?: number,
}[]) => (items?.length ? items.reduce((acc, item) => acc + (item?.quantity || 0), 0) : 0)

export const getNumberOfItemsOnTheCart = (cart: StandardCart | null | undefined) => (
  !cart
    ? 0
    : sumOfQuantityOfItems(cart.lineItems)
)

// We need stateless for the Desktop version -- there is 2 components and they need to have 1 state
export const CartPreviewStateless: React.FC<CartPreviewProps> = ({
  pageType,
  styles,
  onClick = () => {},
  setPreviewVisible,
  previewVisible,
}) => {
  const { cart } = useCart()
  const { customer } = useCustomer()
  const lineItems = cart?.lineItems

  useEffect(() => {
    if (!cart?.lineItems.length) {
      setPreviewVisible(false)
    }
  }, [cart, setPreviewVisible])

  const fetchCart = useFetchCart()
  const quantity = lineItems?.reduce((item: number, next) => item + next.quantity, 0) || 0

  const countPill = (typeof quantity === 'number' ? quantity : '...').toString()

  const togglePreview = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | undefined) => {
    event?.stopPropagation?.()
    event?.preventDefault?.()
    setPreviewVisible(true)
    const user = !customer ? undefined : {
      email: customer?.email || '',
      zip: customer?.addresses?.[0]?.zip || '',
      phone: customer?.phone || '',
    }

    try {
      if (cart?.lineItems.length) {
        Analytics.onCartView(
          (lineItems || []).map((item) => ({
            ...item,
            isExtendItem: item.isWarranty ?? false,
          })),
          cart.total,
          user,
        )
      }
    } catch (e) {
      error(e)
    }
    document.getElementsByTagName('body')?.[0]
      ?.classList?.add('has-cart')
  }

  const closePreview = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | undefined) => {
    event?.stopPropagation?.()
    event?.preventDefault?.()
    onClick(false)
    setPreviewVisible(false)
    document.getElementsByTagName('body')[0]
      .classList.remove('has-cart')
  }

  const coupon = cart?.coupons?.[0]?.code || ''
  const removeCoupon = useCallback(async () => {
    if (cart?.id) {
      await Cart.removeCoupon(cart?.id, String(coupon))
      await fetchCart()
    }
  }, [cart?.id, coupon, fetchCart])

  const [previousCart, setPreviousCart] = useState<undefined | null | StandardCart>(cart)

  useEffect(() => {
    const currentNumberOfItems = getNumberOfItemsOnTheCart(cart)
    const previousNumberOfItems = getNumberOfItemsOnTheCart(previousCart)

    if (previousCart !== undefined && currentNumberOfItems > 0
      && currentNumberOfItems !== previousNumberOfItems
    ) {
      log('CartPreview: Cart changed, opening preview', {
        cart,
        previousCart,
        currentNumberOfItems,
        previousNumberOfItems,
      })
      setPreviewVisible(true)
    }
    setPreviousCart(cart)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, setPreviewVisible, setPreviousCart])

  return (
    <li className={clsx('navUser-item', 'navUser-item--cart', styles?.li)}>
      <a
        id="mini-cart"
        className={clsx('ssr-flex navUser-action', previewVisible && 'is-open', styles?.button)}
        data-cart-preview
        data-dropdown="cart-preview-dropdown"
        data-options="align:right"
        href="/cart.php"
        onClick={previewVisible ? closePreview : togglePreview}
        aria-expanded={Boolean(previewVisible).toString() as 'true' | 'false'}
        data-testid="header-miniCart"
      >
        <img
          src={CartIcon}
          id="navbar-cart-icon"
          alt="cart icon"
          className={clsx('nav-cart-icon', styles?.icon)}
        />
        <span className={clsx('navUser-item-cartLabel', styles?.label)}>Cart</span>
        <span className={clsx('cart-quantity', quantity > 0 && 'countPill--positive', styles?.quantity)}>
          {countPill}
        </span>
      </a>
      {pageType !== 'cart' && (
        <PreviewContent
          cart={cart}
          visible={previewVisible}
          lineItems={lineItems || []}
          setQuantity={Cart.updateQuantity}
          onClose={closePreview}
          cartQuantity={countPill}
          removeCoupon={removeCoupon}
          styles={styles?.preview}
        />
      )}
    </li>
  )
}

export default CartPreviewStateless
