import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react'

import type {
  PaymentRequest,
} from '@stripe/stripe-js'
import { useStripe } from '@stripe/react-stripe-js'
import { transformOrderToApplePaySessionData } from '@/services/ApplePay/transformers/transformOrderToApplePaySessionData'
import { initializeCheckout } from '@/services/FramedCheckout/orderServices'
import { startSession } from '@/services/ApplePay/index'
import {
  applePayNotAvailableMessage,
  initializingApplePayCheckoutMessage,
  cartNotSupportedMessage,
} from '@/data/applePayMessages'

import { StandardCart } from '@/types/ShopFront/CheckoutStandards'
import { log } from '@/services/Log'
import { isFeatureEnabled } from '@/helpers/isFeatureEnabled'
import applePayModalContentBuilder from '@/components/ApplePay/applePayModalContentBuilder'
import { ApplePaySessionWithStripe } from '@/services/ApplePay/Stripe/startStripeApplePaySession'
import type { PaymentFlow } from '@/types/ShopFront/PaymentType'

const isApplePayEnabled = () => (
  (
    isFeatureEnabled('isStripeApplePayEnabled')
    && (
      isFeatureEnabled('isStripeCartApplePayEnabled')
      || isFeatureEnabled('isStripeMiniCartApplePayEnabled')
    )
  ) || (
    isFeatureEnabled('applePayEnabled')
    && (
      isFeatureEnabled('miniCartApplePayCtaEnabled')
      || isFeatureEnabled('cartApplePayCtaEnabled')
    )
  )
)

const useCartApplePayCheckout = ({
  paymentRequest,
  cart,
  canUseApplePay,
  paymentFlow,
}: {
  paymentRequest: PaymentRequest | null
  cart: StandardCart | null
  canUseApplePay: boolean
  paymentFlow: PaymentFlow
}) => {
  const stripe = useStripe()
  const ctaRef = useRef<HTMLButtonElement>(null)
  const [hasOngoingSession, setHasOngoingSession] = useState(false)
  const [modalMessage, setModalMessage] = useState<string>('')
  const hasCartWithoutSplitShipment = useMemo(() => (
    !!cart
    && cart.lineItems.length
    && !(
      !!cart.lineItems.find(({ isFurniture }) => isFurniture)
      && !!cart.lineItems.find(({ isFurniture }) => !isFurniture)
    )
  ), [cart])

  const applePayModalContent = useMemo<React.ReactNode>(() => (
    applePayModalContentBuilder(modalMessage)
  ), [modalMessage])

  const startApplePaySession = useCallback(() => {
    if (cart && isApplePayEnabled() && hasCartWithoutSplitShipment && !hasOngoingSession) {
      setHasOngoingSession(true)
      const freeForNewSession = () => Promise.resolve(setHasOngoingSession(false))
      const initializationFunction = async () => {
        const { success, order } = await initializeCheckout()
        if (!success || order?.shipments.length !== 1) {
          throw new Error('Checkout not available with Apple Pay')
        }
        return { newOrder: order }
      }
      if (
        isFeatureEnabled('isStripeApplePayEnabled')
        && (
          isFeatureEnabled('isStripeCartApplePayEnabled')
          || isFeatureEnabled('isStripeMiniCartApplePayEnabled')
        )
      ) {
        ApplePaySessionWithStripe({
          stripe,
          paymentRequest,
          sessionInitializationPromise: initializationFunction(),
          beforeEnding: freeForNewSession,
          walletName: 'applePay',
          paymentFlow,
        })
      } else {
        startSession({
          logPrefix: 'Mini Cart ApplePay Session',
          beforeAbort: freeForNewSession,
          beforeRedirectOnSuccess: freeForNewSession,
          initializationFunction,
          initialPaymentRequest: transformOrderToApplePaySessionData({
            cart,
            subtotal: cart.subtotal,
            taxTotal: cart.total - cart.subtotal,
            totalDiscount: cart.discount,
            shipments: [],
            grandTotal: cart.total,
            outstandingBalance: cart.total,
          }),
          paymentFlow,
        })
      }
    }
  }, [
    cart,
    hasOngoingSession,
    hasCartWithoutSplitShipment,
    paymentRequest,
    stripe,
    paymentFlow,
  ])

  const readyToStartApplePaySession = useMemo(() => (
    isApplePayEnabled() && hasCartWithoutSplitShipment && canUseApplePay
  ), [canUseApplePay, hasCartWithoutSplitShipment])

  useEffect(() => {
    if (
      readyToStartApplePaySession
      && modalMessage === initializingApplePayCheckoutMessage
    ) {
      setModalMessage('Apple Pay is ready')
    } else if (
      !canUseApplePay
      && hasCartWithoutSplitShipment
      && modalMessage === initializingApplePayCheckoutMessage
    ) {
      setModalMessage(applePayNotAvailableMessage)
    } else if (
      !readyToStartApplePaySession
      && modalMessage === initializingApplePayCheckoutMessage
    ) {
      setModalMessage(cartNotSupportedMessage)
    }
  }, [
    modalMessage,
    startApplePaySession,
    canUseApplePay,
    readyToStartApplePaySession,
    hasCartWithoutSplitShipment,
  ])

  const onStartCheckout = useCallback(() => {
    log({
      canUseApplePay,
      readyToStartApplePaySession,
      hasCartWithoutSplitShipment,
    })
    if (!canUseApplePay) {
      setModalMessage(applePayNotAvailableMessage)
    } else if (readyToStartApplePaySession) {
      startApplePaySession()
    } else if (canUseApplePay && !hasCartWithoutSplitShipment) {
      setModalMessage(cartNotSupportedMessage)
    } else {
      setModalMessage(applePayNotAvailableMessage)
    }
  }, [
    canUseApplePay,
    hasCartWithoutSplitShipment,
    readyToStartApplePaySession,
    startApplePaySession,
  ])

  return {
    onStartCheckout,
    modalContent: applePayModalContent,
    onCloseModal: () => setModalMessage(''),
    canUseApplePay,
    ctaRef,
    hideCta: !hasCartWithoutSplitShipment,
  }
}

export default useCartApplePayCheckout
