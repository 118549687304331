import { useEffect, useState } from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import type { PaymentRequest } from '@stripe/stripe-js'
import { error, log } from '@/services/Log'

const toStripeMoney = (amount: number) => (
  parseInt((parseFloat(String(amount)) * 100).toFixed(2), 10)
)

const paymentMethods = ['applePay', 'browserCard', 'link', 'googlePay'] as const
const disabledWallet = (paymentMethod: 'applePay' | 'link') => paymentMethods.filter((wallet) => wallet !== paymentMethod)

type UseApplePayWithStripeArgs = {
  price?: number;
  paymentMethod?: 'applePay' | 'link';
}

export const useCanUseApplePayWithStripe = ({
  price = 0,
  paymentMethod,
}: UseApplePayWithStripeArgs): {
  canUseApplePay: boolean;
  paymentRequest: PaymentRequest | null;
} => {
  const stripe = useStripe()
  const [canUseApplePay, setCanUseApplePay] = useState(false)
  const [paymentRequest, setPaymentRequest] = useState<null | PaymentRequest>(null)
  useEffect(() => {
    let pr: PaymentRequest | null = null
    if (stripe && paymentMethod) {
      log('useCanUseApplePayWithStripe creating stripe.paymentRequest')
      pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: { label: 'Total', amount: toStripeMoney(price) },
        displayItems: [
          { amount: toStripeMoney(price), label: 'subtotal' },
          { amount: 0, label: 'Shipping' },
          { amount: 0, label: 'Tax' },
        ],
        requestPayerName: true,
        requestPayerPhone: true,
        requestPayerEmail: true,
        requestShipping: true,
        disableWallets: disabledWallet(paymentMethod),
      })
      setPaymentRequest(pr)
    }
    return () => {
      pr?.abort()
    }
  }, [stripe, price, paymentMethod])

  useEffect(() => {
    if (paymentRequest && paymentMethod) {
      log('useCanUseApplePayWithStripe checking if paymentRequest.canMakePayment() returns true for Apple Pay')
      paymentRequest.canMakePayment()
        .then((result) => {
          log(`${paymentMethod} is ${!result?.[paymentMethod] ? 'not' : ''} available`)
          setCanUseApplePay(!!result?.[paymentMethod])
        })
        .catch((err) => {
          error(`Failed to assert paymentRequest.canMakePayment: ${String(err)}`, err)
          setCanUseApplePay(false)
        })
    }
  }, [paymentRequest, paymentMethod])

  return {
    canUseApplePay,
    paymentRequest,
  }
}

export default useCanUseApplePayWithStripe
